<template>
  <k-crud-layout :with-search="false">
    <template #header>{{ title }}</template>
    <template #flow-actions>
      <v-btn v-if="canCreateTicket" color="secondary" depressed outlined @click="createTicket" tile>
        {{ $t('ticket.create') }}
      </v-btn>
    </template>
    <template #view.list>
      <k-crud-table ref="table"
                    :headers="crudHeaders"
                    :index-request="indexRequest"
                    language-prefix="ticket.crudHeaders"
                    resource="ticket">
        <template #item.ticketStatusId="{item: {ticketStatusId}}">
          <span>{{ $t(`ticket.ticketStatus.${getTicketStatus(ticketStatusId)}`) }}</span>
        </template>
        <template #item.locationType="{item: {locationType}}">
          <span>{{ locationType.charAt(0).toUpperCase() + locationType.slice(1) }}</span>
        </template>
        <template #item.assignedTo="{item: {assignedTo}}">
          <span>{{ assignedTo != null ? assignedTo : $t('ticket.unassigned') }}</span>
        </template>
        <template #actions="{item}">
          <v-btn :to="{name: detailTo, params: {ticketId: item.id}}"
            icon
          >
            <v-icon>$visible</v-icon>
          </v-btn>
        </template>
      </k-crud-table>
    </template>
  </k-crud-layout>
</template>

<script lang="js">
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import { EventBus } from '@/application/EventBus.js';
import ticketStatus from '@/application/enums/ticketStatus.js';

export default {
  name: 'TicketsListLayout',
  mixins: [querySyncMixin],
  components: {
    KCrudTable,
    KCrudLayout,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    indexRequest: {
      type: Function,
      required: true,
    },
    detailTo: {
      type: String,
    },
    crudHeaders: {
      type: Array,
      required: true,
    },
    canCreateTicket: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      filters: {},
      searchQuery: '',
    };
  },
  methods: {
    createTicket() {
      EventBus.$emit('createTicket', { table: this.$refs.table });
    },
    reloadTable(){
      this.$refs.table.reload();
    },
    getTicketStatus(id) {
      return Object.keys(ticketStatus).find(key => ticketStatus[key] === id);
    },
  },
};
</script>
